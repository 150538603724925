@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap);
body {
  font-family: "Comfortaa", sans-serif;
  font-size: 18px;
  margin: 0;
}
.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu > * {
  margin: 0.5rem 0 0.5rem;
}

.menu a:hover {
  background-image: linear-gradient(90deg, #c793cb, #ec8c9c);

  color: white;
}

.title a:hover {
  background-image: linear-gradient(90deg, #c793cb, #ec8c9c);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
}

.title {
  margin: 10px;
}

a {
  color: black;
  text-decoration: none;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.body {
  flex: 0 1 100%;
  width: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
}

/* email animation */
.my_div {
  transition: all 500ms ease-in-out;
}

.old-text,
.new-text {
  display: block;
  overflow: hidden;
  transition: all 500ms ease-in-out;
}
.old-text {
  height: auto;
  opacity: 1;
  width: auto;
}
.new-text {
  background-image: linear-gradient(90deg, #c793cb, #ec8c9c);
  color: white;
  height: 0;
  opacity: 0;
  width: 0;
}
.my_div:hover .old-text {
  height: 0px;
  opacity: 0;
  width: 0px;
}
.my_div:hover .new-text {
  height: auto;
  opacity: 1;
  width: auto;
}

/* Change Selection Text Color When Highlighting */
::selection {
  background-color: #eea6bc;
  color: #fff;
}

/* Resume Page */
.resume {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.resume-document-container {
  overflow: auto;
  width: min(100vw, 1000px);
}

.menu-link {
  padding: 0 0.25rem 0 0.25rem;
}

.resume-download-container {
  width: min(100vw, 1000px);
  text-align: right;
}

.resume-download {
  color: lightgray;
}

.resume-download:hover {
  background-image: linear-gradient(90deg, #c793cb, #ec8c9c);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
}

/* About Page */
.about {
  padding: 0 1rem;
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box
}

.about-text {
  width: 500px;
}

.about-text h3 {
  font-weight: bold;
  text-align: center;
}





.projects {
    padding: 0 1rem;
    flex: 0 1 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
  }
  
