

.projects {
    padding: 0 1rem;
    flex: 0 1 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-direction: column;
  }
  